import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerImage from "../img/banner.jpg"
import HomeBanner01 from "../img/home-banner-05.jpg"
import HomeBanner02 from "../img/home-banner-02.jpg"
import HomeBanner03 from "../img/home-banner-03.jpg"
import HomeBanner04 from "../img/home-banner-04.jpg"
import productImage01 from "../img/products/product01.jpg"
export default function Home() {
  return (
    <div>
      <Helmet>
        <title>Represent Corporate Giftings</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="Represent Corporate Giftings" />
        <meta
          name="description"
          content="Budding entrepreneurs with a team of young minds having a handful
              of experience in advertising & operations to ensure a perfect
              combo of branding and execution."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://representgiftings.com/" />
        <meta
          property="og:description"
          content="Budding entrepreneurs with a team of young minds having a handful
              of experience in advertising & operations to ensure a perfect
              combo of branding and execution."
        />
      </Helmet>
      <Layout>
        <div className="homeBanner">
          <img src={BannerImage}></img>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            <div className="col-md-7  serviceText">
              <h1>Take your brand places</h1>
              <h3>It all begins with the product & design itself</h3>
              <p>
                Customization is not just having a logo on any product…It begins
                with the product and design itself.
                <br></br>
                <br></br>
                We curate products through our personalized approach to empower
                your brand messengers, creating a strong brand recall and
                positioning.
              </p>
            </div>
            <div className="col-md-5 serviceImages">
              <img src={HomeBanner01}></img>
            </div>
          </div>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            <div className="col-md-5 serviceImages DesktopOnly ">
              <img src={HomeBanner02}></img>
            </div>
            <div className="col-md-7  serviceText">
              <h1>Apparels</h1>
              <h3>When we say it’s tailor made. We mean it.</h3>
              <p>
                Corporate Tees & Apparels @ unmatched price & quality from the
                house of manufacturer.<br></br>
                <br></br> Tees, Polos , Hoodies, Jackets, Caps, uniforms.
              </p>
            </div>
            <div className="col-md-5 serviceImages MobileOnly">
              <img src={HomeBanner02}></img>
            </div>
          </div>
        </div>
        <svg
          xmlns="//www.w3.org/2000/svg"
          version="1.1"
          className="svg-filters"
          style={{ display: "none" }}
        >
          <defs>
            <filter id="marker-shape">
              <feTurbulence
                type="fractalNoise"
                baseFrequency="0 0.15"
                numOctaves="1"
                result="warp"
              />
              <feDisplacementMap
                xChannelSelector="R"
                yChannelSelector="G"
                scale="30"
                in="SourceGraphic"
                in2="warp"
              />
            </filter>
          </defs>
        </svg>
        <div className="container paddingSuper">
          <div className="row">
            <div className="col-md-7 serviceText">
              <h1>Bags</h1>
              <h3>Carry your brand with swag & comfort</h3>
              <p>
                International design bags to suit all your needs. We create &
                customize bags based on the utility.<br></br>
                <br></br> Slim Bags, Business Series, Messenger bags, Duffel
                bags, Trolley, Toiletry kit
              </p>
            </div>
            <div className="col-md-5 serviceImages">
              <img src={HomeBanner03}></img>
            </div>
          </div>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            <div className="col-md-5 serviceImages DesktopOnly">
              <img src={HomeBanner04}></img>
            </div>
            <div className="col-md-7 serviceText">
              <h1>Gadgets</h1>
              <h3>Gift your techies with the latest technology.</h3>
              <p>
                You name it…We have it<br></br>
                <br></br>
                Speaker, Headphones, Ear -pods , Pen drive, USB hub, Smart
                devices.
              </p>
            </div>
            <div className="col-md-5 serviceImages MobileOnly">
              <img src={HomeBanner04}></img>
            </div>
          </div>
        </div>
        <div className="featured">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>Featured Products</h2>
              </div>
              <div className="col-md-3">
                <div className="featuredProducts">
                  <div className="featuredProductsImage">
                    <img src="https://representgiftings.com/images/home-tshirt.jpg"></img>
                  </div>
                  <div className="featuredProductsTitle">
                    <p>Polo T-Shirts</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="featuredProducts">
                  <div className="featuredProductsImage">
                    <img src="https://representgiftings.com/images/home-bag.jpg"></img>
                  </div>
                  <div className="featuredProductsTitle">
                    <p>Laptop Backpack</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="featuredProducts">
                  <div className="featuredProductsImage">
                    <img src="https://representgiftings.com/images/home-bluetooth.jpg"></img>
                  </div>
                  <div className="featuredProductsTitle">
                    <p>Bluetooth Speakers & Headphones</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="featuredProducts">
                  <div className="featuredProductsImage">
                    <img src="https://representgiftings.com/images/home-diaries.jpg"></img>
                  </div>
                  <div className="featuredProductsTitle">
                    <p>Diaries & Planners</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
